<template>
  <div class="aboutOurCompany">
    <div class="title"><h3>جميع الأخبار</h3></div>
    <div class="container">
      <v-flex>
        <v-row v-if="allNews.length >= 1">
          <v-col cols="12" lg="12" md="12" sm="12" xs="12" class="mt-10">
            <v-toolbar color="indigo" dark>
              <v-toolbar-title>
                أرشيف الأخبار
              </v-toolbar-title>
            </v-toolbar>
            <div class="transaction pa-5">
              <div class="head">
                <div class="row">
                  <div class="col-2 " justify="center">الصوره</div>
                  <div class="col-2">تاريخ الخبر</div>
                  <div class="col-8 ">عنوان الخبر</div>
                </div>
                <div class="items">
                  <div v-for="(selectNew, i) in selectFourNews" :key="i">
                    <router-link
                      class="row routerStyle"
                      :to="{
                        name: 'News',
                        params: { userId: selectNew.slug },
                      }"
                    >
                      <div
                        class="col-2 linkStyle"
                        v-for="(elem, index) in selectNew.news_images"
                        :key="elem"
                        v-bind:selected="index == 1"
                        v-show="elem.main_image == 1"
                      >
                        <v-img
                          width="100"
                          height="100"
                          :src="
                            'https://backend.ascww.org/api/news/image/' +
                              elem.path
                          "
                        />
                      </div>

                      <div class="col-2 linkStyle mt-10">
                        {{ selectNew.created_at.substring(0, 10) }}
                      </div>
                      <div class="col-8 linkStyle mt-10">
                        {{ selectNew.title }}
                      </div>
                    </router-link>
                    <hr
                      class="grey--text"
                      v-if="!(i == lengthPigination.length - 1)"
                    />
                  </div>
                  <div class="text-center" v-show="allNews.length > 3">
                    <v-pagination
                      v-model="page"
                      :total-visible="totalVisable"
                      :length="lengthPigination"
                      prev-icon="mdi-menu-right"
                      next-icon="mdi-menu-left"
                    ></v-pagination>
                  </div>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>

        <v-row v-else
          ><v-img
            :src="loadingLink"
            width="200px"
            height="200px"
            contain
            class="loadImgClass"
          ></v-img
        ></v-row>
      </v-flex>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loadingLink: require("@/assets/Images/load.svg"),
      page: 1,
      headers: [
        {
          text: "Dessert (100g serving)",
          align: "start",
          value: "name",
        },
        { text: "Category", value: "category" },
      ],
    };
  },
  methods: {},
  computed: {
    allNews() {
      return this.$store.state.newsArrayStore;
    },
    lengthPigination() {
      return Math.ceil(this.allNews.length / 3);
    },
    selectFourNews() {
      var negativeLength = this.allNews.length - 3 * this.page;
      if (negativeLength == -1) {
        return this.allNews.slice(0, 2).reverse();
      } else if (negativeLength == -2) {
        return this.allNews.slice(0, 1).reverse();
      } else {
        return this.allNews
          .slice(
            this.allNews.length - 3 * this.page,
            this.allNews.length - (this.page - 1) * 3
          )
          .reverse();
      }
    },
    totalVisable() {
      if (this.lengthPigination > 5) {
        return 5;
      } else return this.lengthPigination;
    },
  },
  created() {
    this.$store.dispatch("findSelectedItem");
  },
  mounted: {},
};
</script>
<style>
.imgClosed {
  margin-right: 200px;
}
.linkTender {
  text-decoration: none;
  color: indigo !important;
  font-weight: bold;
}
.linkStyle {
  text-decoration: none;
  color: black;
}
.routerStyle {
  margin: 1px;
  text-decoration: none;
}
.routerStyle:hover {
  background: rgba(128, 128, 128, 0.158);
}
</style>
